<template>
  <b-card-code :title="this.$route.meta.pageTitle">

    <b-row class="mb-2">
        <b-col>
          <b-button-group>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="loading"
                @click="$router.push('/stats/chagas')"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
              />
              <span class="align-middle">Agregar</span>
            </b-button>
          </b-button-group>
        </b-col>
      <b-col class="text-right">
        <b-button-group>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              :disabled="loading"
              @click="onBtExport()"
          >
            <feather-icon
                icon="DownloadIcon"
                class="mr-50"
            />
            <span class="align-middle">Exportar a Excel</span>
          </b-button>
        </b-button-group>
      </b-col>
      </b-row>

    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..."/>
      Cargando...
    </div>

    <div v-if="rowData.length > 0">
      <ag-grid-vue
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine custom-grid"
          ref="agGrid"
          @grid-ready="onGridReady"
          @first-data-rendered="onFirstDataRendered"
          domLayout='autoHeight'
          :columnDefs="columnDefs"
          :autoGroupColumnDef="this.gridOptions.autoGroupColumnDef"
          :defaultColDef="this.gridOptions.defaultColDef"
          :excelStyles="this.gridOptions.excelStyles"
          :suppressRowClickSelection="true"
          :groupSelectsChildren="true"
          :pagination="true"
          :paginationPageSize="this.gridOptions.paginationPageSize"
          :rowData="rowData"
          :defaultExcelExportParams="this.gridOptions.defaultExcelExportParams"
          :cacheQuickFilter="true">
        >
      </ag-grid-vue>
    </div>
    <b-row v-if="rowData.length === 0">
      <b-col>
        No se encontraron registros en el rango de fecha.
      </b-col>
    </b-row>


    <!--    Toast-->
    <b-toast id="example-toast" variant="primary">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
              src="/logo.jpeg"
              class="mr-1"
              height="18"
              width="18"
              alt="Icon"
          />
          <strong class="mr-auto">Alerta</strong>
        </div>
      </template>
      <span>La fecha <strong>Desde</strong> debe ser menor a la fecha <strong>Hasta</strong>. </span>
    </b-toast>
  </b-card-code>
</template>

<script>
import {
  BAlert, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButtonGroup,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner, BFormFile, BForm
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import {AgGridVue} from "ag-grid-vue";
import environment from "@/environment";
import flatPickr from 'vue-flatpickr-component'
import vSelect from "vue-select";
import {gridDef} from "@/views/stats/ag-grid-conf";
import {format} from "date-fns";


export default {
  name: 'RodenList',
  components: {
    BAlert,
    BForm, BFormFile,
    BButtonGroup,
    flatPickr,
    vSelect,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BCardCode,
    AgGridVue
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      columnDefs: [
        {
          headerName: "Fecha",
          field: "dateE",
          valueFormatter: (params) => {
            return params.value ? format(new Date(params.value), 'dd-MM-yyyy') : '';
          },
          resizable: true
        },
        { headerName: "Semana", field: "week", resizable: true },
        {
          headerName: "Región",
          field: 'regionLabel',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "Distrito",
          field: 'districtLabel',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "Corregimiento",
          field: 'corregimientoLabel',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "Localidad",
          field: 'localidadLabel',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "Semana N°",
          field: 'semana',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "N°",
          field: 'numero',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "N° Casas Exist.",
          field: 'numCasasExis',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "N° Casas Insp.",
          field: 'numCasasInsp',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: "N° Casas Rocia-Das",
          field: 'numCasasRoci',
          resizable: true,
          maxWidth: 150
        },

        {
          headerName: 'Viviendas Infectadas',
          headerClass: 'headerCenter',
          children: [
            {
              headerName: "N° Casas Infectada",
              field: 'numCasasInfec',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Con Rhodnius ..p.",
              field: 'numCasasRhod',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Con Triatoma.d.",
              field: 'numCasasTria',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Porcentaje Inf.",
              field: 'porcentajeInf',
              resizable: true,
              maxWidth: 150
            },
          ]
        },

        {
          headerName: 'Colonización',
          headerClass: 'headerCenter',
          children: [
            {
              headerName: "RP-Int.",
              field: 'coloRPInt',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "RP-Per.",
              field: 'coloRPPer',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "TD-Int.",
              field: 'coloTDInt',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "TD-Per.",
              field: 'coloTDPer',
              resizable: true,
              maxWidth: 150
            },
          ]
        },
        {
          headerName: 'Cantidad de Chinche',
          headerClass: 'headerCenter',
          children: [
            {
              headerName: 'Intradomiciliario',
              headerClass: 'headerCenter',
              children: [
                {
                  headerName: "RP-A",
                  field: 'intraRPA',
                  resizable: true,
                  maxWidth: 150
                },
                {
                  headerName: "RP-N",
                  field: 'intraRPN',
                  resizable: true,
                  maxWidth: 150
                },
                {
                  headerName: "TD-A",
                  field: 'intraTDA',
                  resizable: true,
                  maxWidth: 150
                },
                {
                  headerName: "TD-N",
                  field: 'intraTDN',
                  resizable: true,
                  maxWidth: 150
                },
              ]
            },
            {
              headerName: 'Peridomciliario',
              headerClass: 'headerCenter',
              children: [
                {
                  headerName: "RP-A",
                  field: 'periRPA',
                  resizable: true,
                  maxWidth: 150
                },
                {
                  headerName: "RP-N",
                  field: 'periRPN',
                  resizable: true,
                  maxWidth: 150
                },
                {
                  headerName: "TD-A",
                  field: 'periTDA',
                  resizable: true,
                  maxWidth: 150
                },
                {
                  headerName: "TD-N",
                  field: 'periTDN',
                  resizable: true,
                  maxWidth: 150
                },
              ]
            }
          ]
        },

        {
          headerName: 'Tipo de Pared',
          headerClass: 'headerCenter',
          children: [
            {
              headerName: "Carton",
              field: 'carton',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Paja",
              field: 'paredPaja',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Penca",
              field: 'paredPenca',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Vara",
              field: 'paredVara',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Tabla",
              field: 'paredTabla',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Barro",
              field: 'paredBarro',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Zinc",
              field: 'paredZinc',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Bloque-Cemento",
              field: 'paredBloque',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Ladrillo",
              field: 'paredLadrillo',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Repello",
              field: 'paredRepello',
              resizable: true,
              maxWidth: 150
            },
          ]
        },

        {
          headerName: 'Tipo de Techo',
          headerClass: 'headerCenter',
          children: [
            {
              headerName: "Paja",
              field: 'techoPaja',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Penca",
              field: 'techoPenca',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Teja",
              field: 'techoTeja',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Zinc",
              field: 'techoZinc',
              resizable: true,
              maxWidth: 150
            },
          ]
        },
        {
          headerName: 'Tipo de Piso',
          headerClass: 'headerCenter',
          children: [
            {
              headerName: "Tierra",
              field: 'pisoTierra',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Tabla",
              field: 'pisoTabla',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Cemento",
              field: 'pisoCemento',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "Otros",
              field: 'pisoOtros',
              resizable: true,
              maxWidth: 150
            },
          ]
        },

        {
          headerName: 'Habitantes',
          headerClass: 'headerCenter',
          children: [
            {
              headerName: ">5",
              field: 'menorIgual',
              resizable: true,
              maxWidth: 150
            },
            {
              headerName: "<5",
              field: 'mayor',
              resizable: true,
              maxWidth: 150
            },
          ]
        },
        {
          headerName: "D/H",
          field: 'dh',
          resizable: true,
          maxWidth: 150
        },
        {
          headerName: 'Editar',
          cellRenderer: params => this.linkEditDocument(params.data._id),
          minWidth: 90
        },
      ],
      rowData: [],
      gridApi: null,
      paginationPageSize: null,
      loading: false,
    };
  },
  computed: {
    rangeFrom() {
      return this.rangeDate.substring(0, 10)
    },
    rangeTo() {
      if (this.rangeDate.length > 11) {
        return this.rangeDate.slice(-10)
      }
    }

  },

  beforeMount() {
    this.paginationPageSize = 50;
    this.paginationNumberFormatter = (params) => {
      return '[' + params.value.toLocaleString() + ']';
    };
    this.gridOptions = gridDef(this.$route.meta.pageTitle)
  },
  mounted() {
    this.searchJsonData()
  },
  methods: {
    linkEditDocument(id) {
      const link = document.createElement('a');
      link.href = `${id}`;
      link.textContent = 'Editar';
      link.className = 'detailsLink';

      return link;
    },
    onBtExport() {
      let params = {
        fileName: `${this.$route.meta.pageTitle}-${this.getCurrentDateTime()}.xlsx`,
        exportMode: 'xlsx',
      };
      console.log(this.gridApi.exportDataAsExcel(params))
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.refreshHeader();
      this.gridApi.refreshCells();
      this.gridApi.setHeaderHeight(24);
    },

    searchJsonData() {
      this.loading = true
      this.$http.get(`${environment.uri}stats/chagas`)
          .then(response => {
            this.rowData = response.data;
            this.loading = false
          })
          .catch(error => {
            console.log(error);
            this.loading = false
          })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import "@/assets/scss/custom-ag.scss";
@import '@core/scss/vue/libs/vue-select.scss';

</style>








